import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";


export const Admin = () => {
 
  const data = useStaticQuery(graphql`
    query {
      js: file(relativePath: { eq: "admin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <AdminSection>
      <IMG fluid={data.js.childImageSharp.fluid} />
      <AdminContent  className='slide-in from-right'>
        <h2>Admin Login</h2>
        <p>
          edbā makes it simple for institute heads to manage subjects, groups,
          classes, and courses. 
        <span>
          The popular course demographics and course list
          allow admins to get a clearer picture and make informed decisions.
        </span>
        <span>
          They can also make necessary changes to the events, notice board, and
          news section.{" "}
        </span>
          
        </p>
      </AdminContent>
    </AdminSection>
  );
};

const AdminSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 29.9em) {
    flex-direction: column-reverse;
    gap: 5rem;
  }
`;
const AdminContent = styled.div`
  display: flex;
  flex-direction: column;
  width: min(35.6rem, 100%);
  gap: 2rem;
  & span {
    display: block;
    margin-top: 1rem;
  }
  @media ${props => props.theme.media.small}{
    text-align: center;
    gap: 1rem;
  }
`;
const IMG = styled(Img)`
  height: auto;
  width: min(65rem, 100%);
`;
export default Admin;

