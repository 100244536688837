function rem(rem : string | TemplateStringsArray) {
  return (
    parseFloat(rem) *
    parseFloat(getComputedStyle(document.documentElement).fontSize)
  );
}
function vh(vh : string | TemplateStringsArray) {
  const v = parseFloat(vh);
  let h = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  return (v * h) / 100;
}

export { rem, vh };
