import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import AppStoreBadge from "../../components/Badges/AppStoreBadge";
import PlayStoreBadge from "../../components/Badges/PlayStoreBadge";


const About = () => {
  const data = useStaticQuery(graphql`
    query {
      js: file(relativePath: { eq: "about.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
        extension
        publicURL
      }
    }
  `);

  return (
    <AboutSection>
      <AboutEdba className="slide-in from-left" >
        <h2>About edbā</h2>

        <p>
          <span>
            Established in 2020, edbā is an educational tech startup that aims to
            make imparting education communicative and suited to the requirements
            of the current times.
          </span>
          <span>
            The app aids teachers and students alike with its vast array of
            tracking features and monitoring tools, making digital learning a
            reality.
          </span>
        </p>


        <H3>Get edbā Today</H3>

        <BadgeContainer>
          <AppStoreBadge />
          <PlayStoreBadge />
        </BadgeContainer>
      </AboutEdba>
      <IMG fluid={data.js.childImageSharp.fluid} />
    </AboutSection>
  );
};

export default About;

const AboutSection = styled.section`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media ${props => props.theme.media.small}{
    gap: 5rem;
  }
`;
const AboutEdba = styled.div`
  display: flex;
  flex-direction: column;
  width: min(46rem, 100%);
  gap: 1.6rem;
  & h2{
    margin-bottom: 0.4rem;
  }
  & span{
    display:block;
  }
  & span+span{
    margin-top: 1.1rem;
  }
  @media ${props => props.theme.media.small}{
    text-align: center;
    gap: 1rem;
    & span{
      display: block;
    }
    & h2{
      margin-bottom: 0rem;
    }
  }
`;

const H3 = styled.h3`
  font-size: 2.8rem;
  margin-top: 2.4rem;
  @media ${props => props.theme.media.small}{
    font-size: 1.8rem;
    margin-top: 2rem;
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  gap: 2.5rem;
  @media ${props => props.theme.media.small}{
    gap: 1.5rem;
    justify-content: center;
  }
`;

const IMG = styled(Img)`
  aspect-ratio: 1.6323/1;
  width: min(62rem, 100%);
`;
