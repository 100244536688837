import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <FooterSection>
      <FooterWrapper>
        <P>
          &copy; Copyright {new Date().getFullYear()} EDBA Software LLP&nbsp;
          <MobileLineBreak>All Rights Reserved.</MobileLineBreak>
        </P>

        <LinksWrapper>
          <a href="/privacy-policy">
            <P>Privacy Policy</P>
          </a>
          <a href="/careers">
            <P>Careers</P>
          </a>
        </LinksWrapper>

      </FooterWrapper>
    </FooterSection>
  );
};

export default Footer;

const MobileLineBreak = styled.span`
  display: inline-block;
`;
const FooterSection = styled.footer`
  display: flex;
  background: var(--primary-color);
  width: 100%;
`;

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
  max-width: 1100px;

  @media (max-width: 29.9em) {
    justify-content: center;
    gap: 1.5rem;
  }
`;
const P = styled.p`
  font-size: 2rem;
  color: white;
  @media ${(props) => props.theme.media.small} {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;
