import React, { useState, useRef, useEffect } from "react";
type CallbackType = () => void;
interface HorizontalSwipeInterface {
  onLeftSwipe: CallbackType;
  onRightSwipe: CallbackType;
}

export default function useHorizontalSwipe({
  onLeftSwipe,
  onRightSwipe,
}: HorizontalSwipeInterface) {
  const [initialX, setInitialX] = useState(Number);
  const [currentX, setCurrentX] = useState(Number);
  const swipeRef = useRef<Element>();
  const handleTouchStart = (e: TouchEvent) => {
    setInitialX(e.touches[0].clientX);
  };
  const handleTouchMove = (e: TouchEvent) => {
    setCurrentX(e.touches[0].clientX);
  };
  const handleTouchEnd = (e: TouchEvent) => {
    const offsetX = initialX - currentX;
    if(!initialX || !currentX){
      return
    }
    if (offsetX < 0) {
      onLeftSwipe();
    } 
    else{
      onRightSwipe();
    }
    setInitialX(null)
    setCurrentX(null)
  };

  const addListeners = () => {
    if (swipeRef.current) {
      swipeRef.current.addEventListener("touchstart", handleTouchStart);
      swipeRef.current.addEventListener("touchmove", handleTouchMove);
      swipeRef.current.addEventListener("touchend", handleTouchEnd);
    }
  };
  const removeListeners = () => {
    if (swipeRef.current) {
      swipeRef.current.removeEventListener("touchstart", handleTouchStart);
      swipeRef.current.removeEventListener("touchmove", handleTouchMove);
      swipeRef.current.removeEventListener("touchend", handleTouchEnd);
    }
  };

  useEffect(() => {
    addListeners();
    return removeListeners;
  }, [initialX, currentX]);

  return swipeRef;
}
