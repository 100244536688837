import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "../styles/global.scss";
import { ThemeProvider } from "styled-components";
import theme from "../utils/Theme";
import {
  Admin,
  HeroHome,
  Footer,
  Description,
  Feature,
  About,
  ContactUs,
} from "../containers";
import favicon from "../assets/images/favicon.ico";

function Index() {
  useEffect(() => {
    const sliders = document.querySelectorAll(".slide-in");
    const appearOptions = {
      threshold: 0,
      rootMargin: "0px 0px -10% 0px",
    };
    const appearOnScroll = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("appear");
          appearOnScroll.unobserve(entry.target);
        }
      });
    }, appearOptions);
    sliders.forEach((slider) => {
      appearOnScroll.observe(slider);
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="theme-color" content="#EAF3FE"></meta>
        <title>edbā</title>
        <link rel="shortcut icon" href={favicon} type="image/x-icon" />
      </Helmet>
      <main>
        <HeroHome />
        <Feature />
        <Description />
        <Admin />
        <About />
        <ContactUs />
      </main>
      <Footer />
    </ThemeProvider>
  );
}

export default Index;
