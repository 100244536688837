import React from "react";
import badge from "../../assets/images/store-badges/app_store_black.svg";
import styled from "styled-components";

const AppStoreBadge = () => {
  return (
    <a
      href="https://apps.apple.com/in/app/edb%C4%81/id1598855380"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Badge src={badge} />
    </a>
  );
};

const Badge = styled.img`
  height: 5rem;
  max-width: 100%;
  @media ${(props) => props.theme.media.small} {
    height: 4.5rem;
  }
`;

export default AppStoreBadge;
