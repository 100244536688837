import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { features, vh, rem } from "./utils";
import useHorizontalSwipe from "./hooks/useHorizontalSwipe";

function Feature() {
  const featureRootRef = useRef();
  const featureImages = useRef();
  const swipeRef = useHorizontalSwipe({
    onLeftSwipe: () => {
      setDisplayImageIndex((index) => (index - 1 + features.length) % features.length);
    },
    onRightSwipe: () => {
      setDisplayImageIndex((index) => (index + 1) % features.length);
    },
  });
  const [displayImageIndex, setDisplayImageIndex] = useState(0);

  let setFocusedFeature = (entries) => {
    entries.forEach((entry) => {
      let targetKey = parseInt(entry.target.getAttribute("data-key"));
      if (entry.isIntersecting) {
        setDisplayImageIndex(targetKey);
        entry.target.classList.add("feature-focused");
        const unFocusedElems = featureRootRef.current.querySelectorAll(
          `.feature-focused:not([data-key="${targetKey}"])`
        );

        unFocusedElems.forEach((e) => {
          e.classList.remove("feature-focused");
          return;
        });
      }
    });
  };
  const getIntesectionOptions = (showIntersectionArea = false) => {
    const main = document.querySelector("main");
    if (showIntersectionArea) main?.classList.add("debug-active");
    const top = (vh`100` - rem`30`) / 2;
    main?.style.setProperty("--top-bottom", `${top}px`);
    main?.style.setProperty("--left-right", "0");
    let options = {
      rootMargin: `-${top}px 0px`,
      threshold: 0.5,
    };
    return options;
  };
  useEffect(() => {
    const options = getIntesectionOptions();
    const targets = featureRootRef.current.querySelectorAll("[data-key]");
    let observer = new IntersectionObserver(setFocusedFeature, options);
    targets.forEach((target) => {
      observer.observe(target);
    });
  }, []);

  return (
    <FeatureSection>
      <FeatureImagesWrapper ref={swipeRef}>
        <FeatureImages ref={featureImages}>
          <Power />
          <VolumeUp />
          <VolumeDown />
          <img
            loading="lazy"
            src={features[displayImageIndex].displayImage}
            alt=""
          />
        </FeatureImages>
      </FeatureImagesWrapper>
      <FeatureNav>
        {features.map((feature, index) => (
          <>
            <Radio
              type="radio"
              id={`feature-${index}`}
              name="feature"
              value={index}
              checked={index === displayImageIndex}
              onChange={() => setDisplayImageIndex(index)}
            />
            <Label htmlFor={`feature-${index}`} />
          </>
        ))}
      </FeatureNav>

      <ContentWrapper>
        <SectionHeading>
          <H2>Features</H2>
          <p>
            <span>
              At edbā, we believe in simplifying education. The app has a
              plethora of features, including attendance tracking, interschool
              communication, assignment delegation and submission, fee
              submission, and more.
            </span>
            <span>
              We aim to streamline the activities of education institutions,
              helping them prepare for a digital tomorrow.
            </span>
          </p>
        </SectionHeading>

        <FeatureList className="scroll-snap-align-center" ref={featureRootRef}>
          {features.map((feature, index) => (
            <FeatureItem key={index} data-key={index}>
              <FeatureIconWrapper bgColor={feature.iconBgColor}>
                <FeatureIcon src={feature.icon} />
              </FeatureIconWrapper>
              <FeatureName>{feature.name}</FeatureName>
              <FeatureDescription>
                {feature.description.map((description) => (
                  <span>{description}</span>
                ))}
              </FeatureDescription>
            </FeatureItem>
          ))}
        </FeatureList>
      </ContentWrapper>
    </FeatureSection>
  );
}

const FeatureSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;
const FeatureNav = styled.div`
  gap: 1rem;
  display: none;
  margin-block-start: 2rem;
  @media (max-width: 29.9em) {
    display: flex;
  }
`;
const Radio = styled.input`
  & {
    display: none;
  }

  &:checked + label {
    background: #747474;
  }
`;
const Label = styled.label`
  & {
    width: 0.8rem;
    aspect-ratio: 1;
    background: #e5e5e5;
    display: block;
    border-radius: 50%;
    cursor: pointer;
    transition: 300ms ease background-color;
  }
`;

const SectionHeading = styled.div`
  margin-block: 14.4rem 25rem;
  @media ${(props) => props.theme.media.small} {
    margin-block: 7.2rem 4rem;
    text-align: center;
  }
`;
const H2 = styled.h2`
  font-size: 4rem;
  margin-block-end: 2rem;
  @media ${(props) => props.theme.media.small} {
    font-size: 2.4rem;
    margin-block-end: 1rem;
  }
`;
const ContentWrapper = styled.div`
  max-width: 56rem;
  & span {
    display: block;
  }
  & span + span {
    margin-top: 1rem;
  }

`;
const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25rem;
  margin-bottom: 40rem;
  @media ${(props) => props.theme.media.small} {
    gap: 4rem;
    margin-bottom: 0;
  }
`;
const FeatureItem = styled.div`
  display: grid;
  gap: 1.5rem 2.5rem;
  scroll-snap-stop: always;
  transition: 300ms color ease;
  color: rgba(0, 0, 0, 0.4);

  @media ${(props) => props.theme.media.small} {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
    & p {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
const FeatureIconWrapper = styled.div`
  background: ${(props) => props.bgColor};
  border-radius: 50%;
  grid-row: span 2;
  align-self: start;
  padding: 3rem;
  position: relative;
  @media ${(props) => props.theme.media.small} {
    align-self: initial;
    padding: 2.5rem;
  }
`;
const FeatureIcon = styled.img`
  position: absolute;
  left: 50%:
  top: 50%;
  width: 2.5em;
  transform: translate(-50%, -50%);
  @media ${(props) => props.theme.media.small}{
    width: 2.1em;

  }
`;
const FeatureName = styled.h3`
  font-size: 2.6rem;
  grid-column: 2;
  @media ${(props) => props.theme.media.small} {
    font-size: 2rem;
  }
`;
const FeatureDescription = styled.p`
  font-size: 2rem;
  grid-column: 2;
  line-height: 3rem;
  color: inherit;

  @media ${(props) => props.theme.media.small} {
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }
`;
const FeatureImagesWrapper = styled.div`
  position: sticky;
  top: max(5vh, (100vh - 85rem) / 2);
  height: clamp(65rem, 90vh, 85rem);
  width: fit-content;
  @media (max-width: 29.9em) {
    position: static;
    height: 51rem;
  }
`;
const PhoneBtn = styled.span`
  position: absolute;
  width: 0.4rem;
  height: 5rem;
  background: black;
  --offset: -0.7em;
  @media ${(props) => props.theme.media.small} {
    --offset: -0.35em;
    transform: translateY(-100%);
    display: none;
  }
`;
const VolumeDown = styled(PhoneBtn)`
  right: var(--offset);
  top: 11em;
  border-radius: 0 100vw 100vw 0;
`;
const VolumeUp = styled(VolumeDown)`
  top: 17em;
`;
const Power = styled(PhoneBtn)`
  height: 7rem;
  top: 17rem;
  left: var(--offset);
  border-radius: 100vw  0 0 100vw ;

`;
const FeatureImages = styled.div`
  & {
    height: 100%;
    width: fit-content;
    max-width: 100%;
    border-radius: 4rem;
    position: relative;
  }
  &::after {
    position: absolute;
    width: 13.5rem;
    height: 0.5rem;
    background: black;
    bottom: 1.6rem;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%);
    border-radius: 100vw;
  }
  &::before {
    position: absolute;
    content: "";
    inset: -0.3rem;
    bottom: -0.4rem;
    border-radius: inherit;
    border: 0.5rem solid black;
    user-select: none;
  }
  & > img {
    max-height: 100%;
    border-radius: inherit;
    object-fit: cover;
  }
  & > div {
    scroll-snap-align: start;
    object-fit: cover;
    border-radius: 2rem;
    min-height: 81.2rem;
  }
  @media ${(props) => props.theme.media.small} {
    & {
      border-radius: 2.3rem;
      display: flex;
      flex: 1 0 100%;
    }
    & > img {
      border-radius: inherit;
      object-fit: contain;
    }
    &::after {
      height: 0.2rem;
      width: 5.4rem;
      bottom: 1rem;
    }
    &::before {
      border-width: 0.2rem;
      inset: -0.15rem;
      bottom: -0.3rem;
    }
  }
`;

export default Feature;
