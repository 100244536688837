import {
  assignment,
  attendance,
  communication,
  events,
  calenderEvent,
  payment,
} from "../../../assets/images/features";

import {
  assignmentIcon,
  attendanceIcon,
  communicationIcon,
  eventsIcon,
  timetableIcon,
  paymentIcon,
} from "../../../assets/images/icons";
export const features = [
  {
    icon: assignmentIcon,
    iconBgColor: "#9956C833",
    name: "Assignments",
    description: [
      "Assigning and submitting tasks was never this easy for teachers and students! edbā keeps the process simple for teachers to assign tasks to students directly through the app, making it easy to check the submissions and pending assignments. ",
      "Students can see the new projects and their respective due dates on the dashboard. There is also a discussion section for seeking clarification.",
    ],
    displayImage: assignment,
  },
  {
    icon: attendanceIcon,
    iconBgColor: "#0ABFF333",
    name: "Attendance",
    description: [
      "This feature allows teachers to take a class and individual student attendance easily. Select from the different filters (audience type, batch, division, and student name) to gather the required data. ",
      "Students can use this feature to mark their attendance directly from the app.",
    ],
    displayImage: attendance,
  },
  {
    icon: communicationIcon,
    iconBgColor: "#7082F533",
    name: "Communication",
    description: [
      "Users are notified of any NEWS concerning the institution or anything related to academics. ",
      "Notices about fee submission, identity card submission, exam dates, and other topics are also displayed to students and teachers within the app.",
    ],
    displayImage: communication,
  },
  {
    icon: eventsIcon,
    iconBgColor: "#EE738E33",
    name: "Events",
    description: [
      "The upcoming and past events are visible to users on the app, along with other relevant information. ",
      "Institute admins can post pictures and descriptions of all events directly from the app.",
    ],
    displayImage: events,
  },
  {
    icon: timetableIcon,
    iconBgColor: "#2B78CA33",
    name: "Timetable",
    description: [
      "edbā allows teachers to view their timetables from the app. The table mentions the topic, time slot, and class details for convenience. ",
      "Students, too, have access to their timetable, citing the time slot and the lecture topic. ",
    ],
    displayImage: calenderEvent,
  },
  {
    icon: paymentIcon,
    iconBgColor: "#9956C833",
    name: "Fees / Payment",
    description: [
      "No more standing in long queues to pay your school fee using edbā, you can pay directly from the app with one click.",
      "Keep track of the upcoming fee and access your previous payment receipts, all from one app for your convenience.",
    ],
    displayImage: payment,
  },
];
