import React from "react";
import styled from "styled-components";


function Button({ children, outlined = false, disabled = false, onClick = () => { } }) {
  return <StyledButton outlined={outlined} disabled={disabled} onClick={onClick}>{children}</StyledButton>;
}

const StyledButton = styled.button`
  & {
    background-color: ${(props) =>
      props.outlined ? "transparent" : "var(--primary-color)"};
    padding: 1.2rem 1.6rem;
    border: 2px solid var(--primary-color);
    color: white;
    border-radius: 0.5em;
    font-size: 1.8rem;
    display: flex;
    gap: 0.5rem;
    cursor:  ${(props) => props.disabled ? "not-allowed" : "pointer"};
  }
  &:hover {
    opacity: 0.8;
  }
`;
export default Button;
