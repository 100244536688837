import React, { FormEvent, useState , useRef  } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "../../components/Button/Button";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [isSubmitted , setIsSubmitted] = useState(false)
  
  const siteKey = '6LcgLM4iAAAAAIH-5_6eEQxc4CGe6RLeVQxofg6S'
  const captchaRef = useRef<ReCAPTCHA>(null)
 
  const handleChange = (id: "name" | "email" | "contact") => (e: any) => {
    const { onChange } = data[id];
    const { value } = e.target;
    onChange(value);
    e.target.style.outlineColor = isValid(id, value) ? "green" : "red";
  };
  const data = {
    name: {
      regex: /^[^\s][a-zA-Z\s]+[^\s]$/i,
      onChange: setName,
    },
    email: {
      regex: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
      onChange: setEmail,
    },
    contact: {
      regex: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
      onChange: setContact,
    },
  };

  const isValid = (id: "name" | "email" | "contact", valueToCheck: string) => {
    const { regex } = data[id];
    if (!valueToCheck) return false;
    return !!valueToCheck.match(regex);
  };

  const handleSubmit = async (e: FormEvent) => {
    //@ts-ignore
    setIsSubmitted(true)
    e.preventDefault();
    const captchaValue= captchaRef.current?.getValue()
    
    if (
      !(
        isValid("name", name) &&
        isValid("email", email) &&
        isValid("contact", contact) &&
        captchaValue
      )
    ) {
     
      const errorMessage = !captchaValue ? "Please complete the CAPTCHA" : "Please fill out the details in the correct format"
      toast(errorMessage, {
        type: "error",
      })
      setIsSubmitted(false)
      return
    }
    try {
      const res = await fetch(
        "https://12nxcq7j01.execute-api.us-east-1.amazonaws.com/contact",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ name, email, contact }),
        }
      );
      if (res.status == 200) {
        toast("Details submitted successfully. Thank you!", {
          type: "success"
        })
       
        setName("");
        setEmail("");
        setContact("");
        setIsSubmitted(false)
      } else {
        alert("error");
      }
    } catch (e) {
      console.error(e);
    }

      //@ts-ignore
      captchaRef?.current?.reset();
  };
  return (
    <ContactSection>
      <ContactUsHeading>
        <h2>Contact Us</h2>
        <p>
          We are just a click away! Reach out for more information on features and
          free trials.
        </p>
      </ContactUsHeading>
      <Div>
        <ContactUSContent>
          <Address>
            <h4>Address</h4>
            <p>
              EDBA Software LLP
              <br />
              8<sup>th</sup> Floor, New Viva College,
              <br />
              Chintamani Vihar, Y.K. Nagar,
              <br />
              Virar (West), 401303
            </p>
          </Address>
          <Support>
            <h4>Support</h4>
            <p>
              support@edba.io
              {/* 
                TODO:: Uncomment and Add Contact Number here once decided
                <br />
                +30-2106019311(landline) 
              */}
            </p>
          </Support>
        </ContactUSContent>
        <UserInputs>
          <Form aria-label="Contact us details" onSubmit={handleSubmit}>
            <Input
              type="text"
              id="name"
              placeholder="Name"
              aria-label="Your name"
              value={name}
              onChange={handleChange("name")}
            />
            <br />
            <Input
              type="text"
              id="email"
              placeholder="Email ID"
              aria-label="Your email id"
              value={email}
              onChange={handleChange("email")}
            />
            <br />
            <Input
              type="tel"
              inputMode="tel"
              id="contact"
              placeholder="Contact Number"
              aria-label="Your Contact number"
              value={contact}
              onChange={handleChange("contact")}
            />
            <br />
            <CaptchWrapper>
            <RECAPTCHA
              sitekey={siteKey}
              ref={captchaRef}
            />
            </CaptchWrapper>
          
            <Button aria-label="Connect button" disabled = {isSubmitted}>Connect</Button>
            <ToastContainer 
            position="bottom-center"
            autoClose={5000}
            closeOnClick
            />
          </Form>
        </UserInputs>
      </Div>
    </ContactSection>
  );
};


export default ContactUs;

const ContactSection = styled.section``;
const ContactUsHeading = styled.div`
  text-align: center;
  align-items: center;
  gap: 0.63rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 3.9rem;
  @media ${(props) => props.theme.media.small} {
    padding-bottom: 0rem;
    gap: 1rem;
  }
`;

const Div = styled.div`
  display: flex;
  gap: 30rem;
  flex-wrap: wrap-reverse;
  @media ${(props) => props.theme.media.small} {
    gap: 5rem;
    margin-top: 4rem;
  }
`;
const Address = styled.div``;

const Input = styled.input`
  border-radius: 0.5em;
  min-width: 35rem;
  height: 4.8rem;
  margin-bottom: 2.4rem;
  padding: 1.2rem 1.6rem;
  border: 1px solid rgb(0, 0, 0, 0.4);
  font-size: 1.6rem;

  &::placeholder {
    color: rgb(0, 0, 0, 0.3);
  }

  @media ${(props) => props.theme.media.small} {
    min-width: min(35rem, 100%);
  }
`;

const ContactUSContent = styled.div`
  flex-direction: column;
  display: flex;
  gap: 2.4rem;
  @media ${(props) => props.theme.media.small} {
    width: 100%;
    gap: 3rem;
  }
`;
const UserInputs = styled.div`
  flex: 1;
`;
const Support = styled.div``;
const Form = styled.form`
  @media ${(props) => props.theme.media.small} {
    margin: 0 auto;
  }
`;

const RECAPTCHA = styled(ReCAPTCHA)`
border-radius: 0.5em;
min-width: 35rem;
margin-bottom: 2.4rem;
font-size: 1.6rem;
@media ${(props) => props.theme.media.small} {
  min-width: min(35rem, 100%);
}
`;


const CaptchWrapper = styled.div`
min-width:35rem;
`