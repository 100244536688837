import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
function PhoneFrame({ src, alt, className }) {
  return (
    <PhoneFrameWrapper className={className}>
      <Power />
      <VolumeUp />
      <VolumeDown />
      {typeof src === "string" ? (
        <NormalImage src={src} alt={alt} />
      ) : (
        <Image fluid={src} alt={alt} />
      )}
    </PhoneFrameWrapper>
  );
}

const PhoneFrameWrapper = styled.div`
  & {
    border: 0.3rem solid black;
    border-radius: 2.56rem;
    position: relative;
  }
  &::after {
    position: absolute;
    // content: "";
    width: 8rem;
    height: 0.29rem;
    background: black;
    bottom: 1.1rem;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%);
    border-radius: 100vw;
  }
  @media ${(props) => props.theme.media.small} {
    & {
      border: 0.2rem solid black;
      border-radius: 1.56rem !important;
    }
    &::after {
      height: 0.1rem;
      width: 2.6rem;
      bottom: 0.7rem;
    }
  }
`;
const Image = styled(Img)`
  width: 100%;
`;
const NormalImage = styled.img`
  width: 100%;
  aspect-ratio: 1/2.16534;
  border-radius: inherit;
`;
const PhoneBtn = styled.span`
  position: absolute;
  width: 0.26rem;
  height: 3.24rem;
  background: black;
  --offset: -0.5rem;
  @media ${(props) => props.theme.media.small} {
    --offset: -0.35em;
    display: none;
    transform: translateY(-100%);
  }
`;
const VolumeDown = styled(PhoneBtn)`
  right: var(--offset);
  top: 7.3rem;
`;
const VolumeUp = styled(VolumeDown)`
  top: 11.19rem;
`;
const Power = styled(PhoneBtn)`
  height: 4.54rem;
  top: 11.19rem;
  left: var(--offset);
  border-radius: 100vw  0 0 100vw ;
`;
export default PhoneFrame;
