import React from "react";
import badge from "../../assets/images/store-badges/play_store_black.svg";
import styled from "styled-components";

const PlayStoreBadge = () => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.edba_app&hl=en&gl=US"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Badge src={badge} />
    </a>
  );
};

const Badge = styled.img`
  height: 5rem;
  max-width: 100%;
  @media ${(props) => props.theme.media.small} {
    height: 4.5rem;
  }
`;

export default PlayStoreBadge;
