import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import PhoneFrame from "../../components/PhoneFrame/PhoneFrame";
import blob from "../../assets/images/blob.svg";
import mobileBlob from "../../assets/images/mobile-blob.svg";
import {
  edbaLogo,
  assignments,
  submissions,
} from "../../assets/images/hero";
import AppStoreBadge from "../../components/Badges/AppStoreBadge";
import PlayStoreBadge from "../../components/Badges/PlayStoreBadge";

function HeroHome() {
  const data = useStaticQuery(graphql`
    query {
      assignments: file(relativePath: { eq: "assignment.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      logo: file(relativePath: { eq: "edba_logo.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      submissions: file(relativePath: { eq: "submission.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <HeroHomeSection>
      <HeroHomeAddress>
        <Logo src={edbaLogo} />
        <H1 className="herohome__heading">Education made simple</H1>
        <P className="herohome__description">
          Making education fun, interactive and simple for students, teachers
          and parents.
        </P>

        <H3>Download the App</H3>
        <BadgeWrapper>
          <AppStoreBadge />
          <PlayStoreBadge />
        </BadgeWrapper>
      </HeroHomeAddress>
      <HeroHomeImagesWrapper>
        <AssignmentImage src={assignments} />
        <SubmissionImage src={submissions} />
      </HeroHomeImagesWrapper>
    </HeroHomeSection>
  );
}

const HeroHomeSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-block-start: 11.5rem;
  position: relative;
  &::before{
    position: absolute;
    content: '';
    left: 50%;
    top: -11.5rem;
    bottom: -8.4rem;
    transform: translate(-50%);
    width: 100vw;
    --bg-path: url('${blob}');
    background: var(--bg-path) no-repeat center bottom;
    background-size: cover;
    z-index: -1;
  }
  @media ${(props) => props.theme.media.small} {
    margin-block-start: 7rem;
    &::before{
      --bg-path: url('${mobileBlob}');
      bottom: -5.6rem;
    }
  }


`;
const P = styled.p`
  margin-block-end: 6.9rem;
  font-size: 2.4rem;

  @media ${(props) => props.theme.media.small} {
    margin-block-end: 3.6rem;
    font-size: 1.4rem;
  }
`;
const HeroHomeAddress = styled.div`
  text-align: center;
  flex: 1;
  max-width: 45rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const HeroHomeImagesWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: start;
  gap: 4.7rem;
  max-width: 100%;
  @media ${(props) => props.theme.media.small} {
    --padding: 5vw;
    padding: 5rem var(--padding) 0rem var(--padding);
    gap: 2.4rem;
  }
`;
const AssignmentImage = styled(PhoneFrame)`
  width: 23rem;
  border-radius: 2.5rem;
`;
const SubmissionImage = styled(AssignmentImage)`
  margin-top: 15.4rem;
  @media ${(props) => props.theme.media.small} {
    margin-top: 7.2rem;
  }
`;
const H3 = styled.h3`
  font-size: 2.8rem;
  margin-block-end: 0.6rem;
  @media ${(props) => props.theme.media.small} {
    margin-block-end: 0.7rem;
    font-size: 1.8rem;
  }
`;
const Logo = styled.img`
  max-width: 22.5rem;
  @media ${(props) => props.theme.media.small} {
    height: 5rem;
  }
`;
const H1 = styled.h1`
  margin-block: 4rem 1.6rem;
  font-size: 3.6rem;
  @media ${(props) => props.theme.media.small} {
    font-size: 2.6rem;
    margin-block: 1.4rem;
  }
`;

const BadgeWrapper = styled.div`
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
`;

export default HeroHome;
