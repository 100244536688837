import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";


function Description() {

  const data = useStaticQuery(graphql`
    query {
      js: file(relativePath: { eq: "description.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <DescriptionSection>
      <SectionContent
        className="slide-in from-left">
        <h2>Multiple Accounts</h2>     
          <p>
            Access multiple accounts from one app. Users can use only one app to
            access numerous accounts from a single device.
          <span>
            Users can log into their profiles with their unique user ID and passwords. This feature
            makes it simple to handle multiple accounts from one app without
            creating any clutter.{" "}
          </span>
          </p>
      </SectionContent>
      <IMG fluid={data.js.childImageSharp.fluid} />
    </DescriptionSection>
  );
}

export default Description;

const DescriptionSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${props => props.theme.media.small}{
    gap: 5rem;
  }
`;
const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: min(48rem, 100%);
  gap: 2rem;
  & span {
    display: block;
    margin-top: 1rem;
  };
  @media ${props => props.theme.media.small}{
    text-align: center;
    gap: 1rem;
  }
`;
const DescriptionPara=styled.div`
display:flex;
flex-direction: column;
gap: 1rem;
@media ${props => props.theme.media.small}{
  text-align: center;
  gap: 0rem;
}
`
const IMG = styled(Img)`
  width: min(37rem, 100%);
  @media ${props => props.theme.media.small}{
    max-width: 24.2rem;
  }
`;

